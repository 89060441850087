import React from 'react';
import './SpaceShipDialog.css'; // Add your own CSS for styling

const SpaceShipDialog = ({ isOpen, onClose, scrollTo }) => {
    if (!isOpen) return null;

    return (
        <div className="dialog-overlay">
            <div className="dialog">
                <p className="h3alt">Hey, where do you want to go?</p>
                <button onClick={() => { scrollTo('intro'); onClose(); }}>Intro</button>
                <button onClick={() => { scrollTo('projects'); onClose(); }}>Projects</button>
                <button onClick={() => { scrollTo('languages'); onClose(); }}>Languages</button>
                <button onClick={() => { scrollTo('aboutMe'); onClose(); }}>About Me</button>
                <button onClick={onClose}>Close</button>
            </div>
        </div>
    );
};

export default SpaceShipDialog;
