import React from 'react';
import './CodeSnippet.css';

const CodeSnippet = ({ scrollToProjects, scrollToLanguages, scrollToAboutMe }) => {
    return (
        <div className="code-section">
            <h1>Full Stack Developer</h1>
            <div className="code-snippet-top">
                <div style={{paddingLeft: '30px'}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#002c5e"
                         className="bi bi-circle" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                    </svg>
                </div>
                <div style={{paddingLeft: '10px'}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#002c5e"
                         className="bi bi-circle" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                    </svg>
                </div>
                <div style={{paddingLeft: '10px'}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#002c5e"
                         className="bi bi-circle" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                    </svg>
                </div>
            </div>
            <div className="code-snippet">
                <p></p>
                <p className="indent0">public class PortfolioJourney {'{'}</p>
                <p className="indent1">public void WelcomeVisitor(string frontPage) {'{'}</p>
                <p className="indent2">if (frontPage == "Home") {'{'}</p>
                <p className="indent3">Console.WriteLine("👋 Greetings, intrepid Internet wanderer! Welcome to Bryan's
                    digital domain!");</p>
                <p className="indent3">Navigation.DisplayOptions(new List&lt;string&gt;);</p>
                <div className="indent4">
                    <button type='button' className='btn btn-outline-light'
                            onClick={() => {
                                const email = 'bryan.tomadon@gmail.com';
                                const subject = 'Contact';
                                const body = 'Please enter your message here.';
                                const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
                                window.open(mailtoLink, '_blank');
                            }}>"📧 Send me your quests via electronic carrier pigeon (Contact)",
                    </button>
                    <button type='button' className='btn btn-outline-light' onClick={scrollToProjects}>"🛠️ All the mighty quests I have fulfilled
                        (Projects)",
                    </button>
                    <button type='button' className='btn btn-outline-light' onClick={scrollToLanguages}>"💬 Behold the programming tongues I converse
                        with
                        machines (Languages)",
                    </button>
                    <button type='button' className='btn btn-outline-light'onClick={scrollToAboutMe}>"🧑‍💻 Step into the depth of my origin story
                        (About Me)"
                    </button>
                </div>
                <p className="indent3">{'}'});</p>
                <p className="indent2">{'}'}</p>
                <p className="indent1">{'}'}</p>
            </div>
        </div>
    );
};

export default CodeSnippet;
