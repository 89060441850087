import React, {useEffect} from 'react';
import './Languages.css';
import {gsap} from 'gsap';
import {Draggable} from 'gsap/Draggable';

gsap.registerPlugin(Draggable);

const Languages = () => {
    useEffect(() => {
        const cards = gsap.utils.toArray(".creative-pro1");
        const dragDistancePerRotation = 3000;
        let radius = 520;
        let startProgress;
        const proxy = document.createElement("div");
        const progressWrap = gsap.utils.wrap(0, 1);
        let spin;

        function updateSpinAnimation(newRadius) {
            radius = newRadius;
            if (spin) {
                spin.kill(); 
            }
            spin = gsap.fromTo(cards, {
                rotationY: i => i * 360 / cards.length
            }, {
                rotationY: "-=360",
                duration: 60,
                ease: "none",
                repeat: -1,
                transformOrigin: "50% 50% " + -radius + "px"
            });
        }

        function checkMediaQueries() {
            if (window.matchMedia("(max-width: 600px)").matches) {
                updateSpinAnimation(200); // Smaller radius for small screens
            } else if (window.matchMedia("(min-width: 601px) and (max-width: 992px)").matches) {
                updateSpinAnimation(420); // Medium radius for medium screens
            } else if (window.matchMedia("(min-width: 993px)").matches) {
                updateSpinAnimation(520); // Larger radius for large screens
            }
        }

        checkMediaQueries(); // Initial check
        window.addEventListener('resize', checkMediaQueries); // Add listener for screen size changes

        Draggable.create(proxy, {
            trigger: ".demoWrapper1",
            type: "x",
            inertia: true,
            allowNativeTouchScrolling: true,
            onPress() {
                gsap.killTweensOf(spin);
                spin.timeScale(0);
                startProgress = spin.progress();
            },
            onDrag: function() {
                let p = startProgress + (this.startX - this.x) / dragDistancePerRotation;
                spin.progress(progressWrap(p));
            },
            onThrowUpdate: function() {
                let p = startProgress + (this.startX - this.x) / dragDistancePerRotation;
                spin.progress(progressWrap(p));
            },
            onRelease() {
                if (!this.tween || !this.tween.isActive()) {
                    gsap.to(spin, { timeScale: 1, duration: 1 });
                }
            },
            onThrowComplete() {
                gsap.to(spin, { timeScale: 1, duration: 1 });
            }
        });

        return () => {
            window.removeEventListener('resize', checkMediaQueries); // Cleanup listener on unmount
        };
    }, []);


    return (
        <div className="language-section">
            <div className="background-image"></div>
            <div className="demoWrapper1">
                <div className="card box1 creative-pro1">
                    <div className="icon">
                        <i className="fa-brands fa-github"></i>
                    </div>
                    <div className="card-body p-6">Github/Gitlab
                        <small className="card-clr d-block mb-3"><br/>Utilized for managing all projects and version control.</small>
                    </div>
                </div>
                <div className="card box1 creative-pro1">
                    <div className="icon">
                        <i className="fa-brands fa-wordpress"></i>
                    </div>
                    <div className="card-body p-6">Wordpress
                        <small className="card-clr d-block mb-3"><br/>Developed a custom plugin for a water polo tournament.</small>
                    </div>
                </div>
                <div className="card box1 creative-pro1">
                    <div className="icon">
                        <i className="fa-brands fa-slack"></i>
                    </div>
                    <div className="card-body p-6">Slack
                        <small className="card-clr d-block mb-3"><br/>Facilitated most project communications through Slack.</small>
                    </div>
                </div>
                <div className="card box1 creative-pro1">
                    <div className="icon">
                        <i className="fa-brands fa-docker"></i>
                    </div>
                    <div className="card-body p-6">Docker
                        <small className="card-clr d-block mb-3"><br/>Hosted all my websites in Docker containers on my home server.</small>
                    </div>
                </div>
                <div className="card box1 creative-pro1">
                    <div className="icon">
                        <i className="fa-brands fa-jira"></i>
                    </div>
                    <div className="card-body p-6">Jira
                        <small className="card-clr d-block mb-3"><br/>Managed projects using agile methodologies.</small>
                    </div>
                </div>
                <div className="card box1 creative-pro1">
                    <div className="icon">
                        <i className="fa-brands fa-confluence"></i>
                    </div>
                    <div className="card-body p-6">Confluence
                        <small className="card-clr d-block mb-3"><br/>Documented all project-related information and processes.</small>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Languages;