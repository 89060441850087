import React, { useEffect } from 'react';
import './Languages.css';
import { gsap } from 'gsap';
import { Draggable } from 'gsap/Draggable';

gsap.registerPlugin(Draggable);

const Languages3 = () => {
    useEffect(() => {
        const cards = gsap.utils.toArray(".creative-pro3");
        const dragDistancePerRotation = 3000;
        let radius = 520;
        let startProgress;
        const proxy = document.createElement("div");
        const progressWrap = gsap.utils.wrap(0, 1);
        let spin;

        function updateSpinAnimation(newRadius) {
            radius = newRadius;
            if (spin) {
                spin.kill();
            }
            spin = gsap.fromTo(cards, {
                rotationY: i => i * 360 / cards.length
            }, {
                rotationY: "-=360",
                duration: 60,
                ease: "none",
                repeat: -1,
                transformOrigin: "50% 50% " + -radius + "px"
            });
        }

        function checkMediaQueries() {
            if (window.matchMedia("(max-width: 600px)").matches) {
                updateSpinAnimation(200); // Smaller radius for small screens
            } else if (window.matchMedia("(min-width: 601px) and (max-width: 992px)").matches) {
                updateSpinAnimation(420); // Medium radius for medium screens
            } else if (window.matchMedia("(min-width: 993px)").matches) {
                updateSpinAnimation(520); // Larger radius for large screens
            }
        }

        checkMediaQueries(); // Initial check
        window.addEventListener('resize', checkMediaQueries); // Add listener for screen size changes

        Draggable.create(proxy, {
            trigger: ".demoWrapper3",
            type: "x",
            inertia: true,
            allowNativeTouchScrolling: true,
            onPress() {
                gsap.killTweensOf(spin);
                spin.timeScale(0);
                startProgress = spin.progress();
            },
            onDrag: function() {
                let p = startProgress + (this.startX - this.x) / dragDistancePerRotation;
                spin.progress(progressWrap(p));
            },
            onThrowUpdate: function() {
                let p = startProgress + (this.startX - this.x) / dragDistancePerRotation;
                spin.progress(progressWrap(p));
            },
            onRelease() {
                if (!this.tween || !this.tween.isActive()) {
                    gsap.to(spin, { timeScale: 1, duration: 1 });
                }
            },
            onThrowComplete() {
                gsap.to(spin, { timeScale: 1, duration: 1 });
            }
        });

        return () => {
            window.removeEventListener('resize', checkMediaQueries); // Cleanup listener on unmount
        };
    }, []);

    return (
        <div className="language-section">
            <div className="demoWrapper3">
                <div className="card box3 creative-pro3">
                    <div className="icon">
                        <i className="fa-brands fa-ubuntu"></i>
                    </div>
                    <div className="card-body p-6">Ubuntu
                        <small className="card-clr d-block mb-3"><br/>Developed primarily on Linux, proficient with terminal commands and environment management.</small>
                    </div>
                </div>
                <div className="card box3 creative-pro3">
                    <div className="icon">
                        <img src="/images/unrealIcon.png" className="icon-brand" alt="Unreal"></img>
                    </div>
                    <div className="card-body p-6">Unreal Engine
                        <small className="card-clr d-block mb-3"><br/>Extensively worked with Unreal Engine, creating
                            video games for school projects and personal use.</small>
                    </div>
                </div>
                <div className="card box3 creative-pro3">
                    <div className="icon">
                        <i className="fa-brands fa-bootstrap"></i>
                    </div>
                    <div className="card-body p-6">Bootstrap
                        <small className="card-clr d-block mb-3"><br/>Utilized Bootstrap for responsive and mobile-first web development.</small>
                    </div>
                </div>
                <div className="card box3 creative-pro3">
                    <div className="icon">
                        <i className="fa-brands fa-microsoft"></i>
                    </div>
                    <div className="card-body p-6">Microsoft
                        <small className="card-clr d-block mb-3"><br/>Extensive experience with Microsoft frameworks
                            including .NET, ASP.NET, and Azure services.</small>
                    </div>
                </div>
                <div className="card box3 creative-pro3">
                    <div className="icon">
                        <i className="fa-brands fa-raspberry-pi"></i>
                    </div>
                    <div className="card-body p-6">Raspberry-Pi
                        <small className="card-clr d-block mb-3"><br/>Managed and configured home servers on a Raspberry-Pi for hosting various projects and services.</small>
                    </div>
                </div>
                <div className="card box3 creative-pro3">
                    <div className="icon">
                        <i className="fa-brands fa-angular"></i>
                    </div>
                    <div className="card-body p-6">Angular
                        <small className="card-clr d-block mb-3"><br/>Completed several projects using Angular for
                            building dynamic single-page applications.</small>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Languages3;
