import React, { Component, createRef } from 'react';
import Header from './Header';
import CodeSnippet from './CodeSnippet';
import Projects from "./Projects";
import Languages from "./Languages";
import Languages2 from "./Languages2";
import Languages3 from "./Languages3";
import AboutMe from "./AboutMe";
import TutorialModal from './TutorialModal';
import SpaceShipDialog from "./SpaceShipDialog";
import LanguageExplanation from "./languageExplanation";
export class Home extends Component {
  static displayName = Home.name;

    constructor(props) {
        super(props);
        this.projectsRef = createRef();
        this.languagesRef = createRef();
        this.aboutMeRef = createRef();
        this.state = {
            tutorialStep: 1,
            tutorialOpen: true,
        };
    }
    scrollToRef = (ref) => {
        if (ref.current) {
            ref.current.scrollIntoView({ behavior: 'smooth' });
        }
    }
    handleNextStep = () => {
        this.setState(prevState => {
            const nextStep = prevState.tutorialStep + 1;
            if (nextStep > 4) {
                return { tutorialOpen: false }; 
            }
            switch (nextStep) {
                case 2:
                    break;
                case 3:
                    break;
                case 4:
                    break;
                default:
                    break;
            }
            return { tutorialStep: nextStep };
        });
    }

    handleCloseTutorial = () => {
        this.setState({ tutorialOpen: false });
    }

    handleOpenDialog = () => {
        this.setState({ isDialogOpen: true });
    }

    handleCloseDialog = () => {
        this.setState({ isDialogOpen: false });
    }
    handleScrollTo = (section) => {
        switch (section) {
            case 'intro':
                window.scrollTo({ top: 0, behavior: 'smooth' });
                break;
            case 'projects':
                this.scrollToRef(this.projectsRef);
                break;
            case 'languages':
                this.scrollToRef(this.languagesRef);
                break;
            case 'aboutMe':
                this.scrollToRef(this.aboutMeRef);
                break;
            default:
                break;
        }
    }
  render () {
    return (
        <div>
            <div className="fixed-left">
                <button className="transparent-button moving-rocket" onClick={this.handleOpenDialog}>
                    <img className="image-button" src={`${process.env.PUBLIC_URL}/images/SpaceShip.png`}
                         alt="Profile Image"/>
                    <img className="image-button" src={`${process.env.PUBLIC_URL}/images/RocketFlame2.png`}
                         alt="Profile Image"/>
                </button>
            </div>
            <TutorialModal
                isOpen={this.state.tutorialOpen}
                step={this.state.tutorialStep}
                onClose={this.handleCloseTutorial}
                onNext={this.handleNextStep}
            />

            <div className="intro">
                <CodeSnippet
                    scrollToProjects={() => this.scrollToRef(this.projectsRef)}
                    scrollToLanguages={() => this.scrollToRef(this.languagesRef)}
                    scrollToAboutMe={() => this.scrollToRef(this.aboutMeRef)}
                />
            </div>

            <div className="projects" ref={this.projectsRef}>
                <Projects/>
            </div>
            <div className="languages-intro">
                <h2>Languages and Technologies</h2>
                <p className="pAlternate">In this section, I provide a brief overview of the programming languages and technologies I have used.</p>
                <p className="pAlternate">The languages and technologies listed here are among my primary skills, though I have experience with many others as well.</p>
            </div>
            <div className="language-tech">
                <div className="languages" ref={this.languagesRef}>
                    <div>
                        <Languages/>
                    </div>
                    <div>
                        <Languages2/>
                    </div>
                    <div>
                        <Languages3/>
                    </div>
                </div>
                <div className="language-explanation">
                    <LanguageExplanation/>
                </div>
            </div>
            
            <div className="AboutMe" ref={this.aboutMeRef}>
                <AboutMe/>
            </div>
            <SpaceShipDialog
                isOpen={this.state.isDialogOpen}
                onClose={this.handleCloseDialog}
                scrollTo={this.handleScrollTo}
            />
        </div>
    );
  }
}
