import React from 'react';
import './Header.css';

const Header = ({ buttonText }) => {
    return (
        <header className="header">
            <button className="header-button">{buttonText}</button>
        </header>
    );
};

export default Header;

