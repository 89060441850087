import React, { useState, useEffect, useRef  } from 'react';
import './Projects.css';
import { gsap } from "gsap";
import ProjectsExplanation from "./ProjectsExplanation";

const Projects = () => {
    const [selectedProject, setSelectedProject] = useState('F4Zero');
    const previousProjectRef = useRef(null);

    const handleButtonClick = (projectName) => {
        if (selectedProject === projectName) {
            return;
        }
        if (selectedProject) {
            gsap.to(`.${selectedProject}`, {
                opacity: 0,
                duration: 0.3,
                onComplete: () => {
                    previousProjectRef.current = selectedProject;
                    setSelectedProject(projectName);
                }
            });
        } else {
            setSelectedProject(projectName);
        }
    };

    useEffect(() => {
        if (selectedProject) {
            // Fade in the new project
            gsap.to(`.${selectedProject}`, {
                opacity: 1,
                duration: 0.3
            });
        }
    }, [selectedProject]);

    return (
        <div className="projects-section">
            <div className="projects-intro">
                <h2>My Projects</h2>
                <p className="pAlternate">Here, I will discuss the various projects I have participated in.</p>
                <p className="pAlternate">Click on any project on the left to get more information.</p>
            </div>
            <div className="projects-container">
                <div className="projects-buttons-container">
                    <h3>Projects I worked on</h3>
                    <button className="project-button-left" onClick={() => handleButtonClick('F4Zero')}>
                        <img className="rounded-circle me-1" width="60" height="60"
                             src={`${process.env.PUBLIC_URL}/images/F4Zero.png`} alt="Profile Image"/>
                        <span className="vr mx-2"></span>
                        F4.Zero: A Web browser racing game
                    </button>
                    <button className="project-button-right" onClick={() => handleButtonClick('E-mersion')}>
                        E-mersion : A tool for learning development
                        <span className="vr mx-2"></span>
                        <img className="rounded-circle me-1" width="60" height="60" src={`${process.env.PUBLIC_URL}/images/UHA40.png`} alt="Profile Image"/>
                    </button>
                    <button className="project-button-left" onClick={() => handleButtonClick('ToonTank')}>
                        <img className="rounded-circle me-1" width="60" height="60" src={`${process.env.PUBLIC_URL}/images/ToonTank.png`} alt="Profile Image"/>
                        <span className="vr mx-2"></span>
                        Toon Tank : A tank-fighting game
                    </button>
                    <button className="project-button-right" onClick={() => handleButtonClick('Hoplacup')}>
                        Hoplacup : A WebApp for water polo tournaments
                        <span className="vr mx-2"></span>
                        <img className="rounded-circle me-1" width="60" height="60" src={`${process.env.PUBLIC_URL}/images/MulhouseWP.png`} alt="Profile Image"/>
                    </button>
                    <button className="project-button-left" onClick={() => handleButtonClick('PokemonAlisa')}>
                        <img className="rounded-circle me-1" width="60" height="60" src={`${process.env.PUBLIC_URL}/images/pikalisa.png`} alt="Profile Image"/>
                        <span className="vr mx-2"></span>
                        PokemonAlisa : An art-sharing website focused on Pokémon
                    </button>
                    <button className="project-button-right" onClick={() => handleButtonClick('NewSkill')}>
                        New Skill : A questionnaire for self-improvement
                        <span className="vr mx-2"></span>
                        <img className="rounded-circle me-1" width="60" height="60" src={`${process.env.PUBLIC_URL}/images/StartNSwitch.png`} alt="Profile Image"/>
                    </button>
                </div>
                <ProjectsExplanation project={selectedProject} />
            </div>
        </div>
    );
};

export default Projects;