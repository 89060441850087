// TutorialModal.js
import React, { useEffect }from 'react';
import './TutorialModal.css'; // Add your own CSS for styling

const TutorialModal = ({ isOpen, step, onClose, onNext }) => {
    useEffect(() => {
        if (isOpen) {
            // Ajouter la classe pour désactiver le défilement
            document.body.classList.add('no-scroll');
        } else {
            // Retirer la classe pour réactiver le défilement
            document.body.classList.remove('no-scroll');
        }

        // Nettoyage de l'effet lors du démontage du composant
        return () => {
            document.body.classList.remove('no-scroll');
        };
    }, [isOpen]);

    if (!isOpen) return null;

    const getStepContent = () => {
        switch (step) {
            case 1:
                return "Hello ! My name is Bryan and Welcome on my Website."+"You can click on any buttons in the terminal bellow to navigate through the website or you can ask the nice SpaceShip to fly you around !";
                
            case 2:
                return "You can click on any buttons in the terminal bellow to navigate through the website.";
            case 3:
                return "Or you can ask the nice SpaceShip to fly you around !";
            case 4:
                return  "You can also have a look at my other websites and please feel free to contact me !";
            default:
                return "";
        }
    };
    

    return (
        <div className="tutorial-modal">
            <div className="tutorial-overlay"></div>
            <div className="tutorial-content">
                <p className="pAlternateBlack pAlternateBlack1">Hello, I'm Bryan. Welcome on my Portfolio !</p>
                <p className="pAlternateBlack">You can click on any buttons in the terminal bellow to navigate through the website or you can ask the nice SpaceShip to fly you around !</p>
                <button className="close-tutorial-button" onClick={onClose}>Got it !</button>
            </div>
            <div className={`highlight highlight-step-1`}></div>
            <div className={`highlight highlight-step-2`}></div>
        </div>
    );
};

export default TutorialModal;
