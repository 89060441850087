import React from 'react';
import './AboutMe.css';

const AboutMe = () => {
    return (
        <div>
            <p>hello</p>
        </div>
    );
};

export default AboutMe;