import React, { useEffect } from 'react';
import './Languages.css';
import { gsap } from 'gsap';
import { Draggable } from 'gsap/Draggable';

gsap.registerPlugin(Draggable);

const Languages2 = () => {
    useEffect(() => {
        const cards = gsap.utils.toArray(".creative-pro2");
        const dragDistancePerRotation = 3000;
        let radius = 520;
        let startProgress;
        const proxy = document.createElement("div");
        const progressWrap = gsap.utils.wrap(0, 1);
        let spin;

        function updateSpinAnimation(newRadius) {
            radius = newRadius;
            if (spin) {
                spin.kill();
            }
            spin = gsap.fromTo(cards, {
                rotationY: i => i * 360 / cards.length
            }, {
                rotationY: "+=360",
                duration: 60,
                ease: "none",
                repeat: -1,
                transformOrigin: "50% 50% " + -radius + "px"
            });
        }

        function checkMediaQueries() {
            if (window.matchMedia("(max-width: 600px)").matches) {
                updateSpinAnimation(200); // Smaller radius for small screens
            } else if (window.matchMedia("(min-width: 601px) and (max-width: 992px)").matches) {
                updateSpinAnimation(420); // Medium radius for medium screens
            } else if (window.matchMedia("(min-width: 993px)").matches) {
                updateSpinAnimation(520); // Larger radius for large screens
            }
        }
        function updateCardVisibility() {
            cards.forEach(card => {
                // Get the rotation value
                const rotationY = gsap.getProperty(card, "rotationY");
                const angle = rotationY % 360;
                const angleInDeg = angle < 0 ? 360 + angle : angle;

                // Define the threshold for visibility (e.g., 90 degrees)
                const perspectiveThreshold = 40; 

                // Check if the card is behind the perspective
                if (angleInDeg > 180 - perspectiveThreshold && angleInDeg < 180 + perspectiveThreshold) {
                    card.style.opacity = '0'; // Hide card
                } else {
                    card.style.opacity = '1'; // Show card
                }
            });

            requestAnimationFrame(updateCardVisibility);
        }
        
        checkMediaQueries();
        updateCardVisibility();
        
        window.addEventListener('resize', checkMediaQueries); // Add listener for screen size changes

        Draggable.create(proxy, {
            trigger: ".demoWrapper2",
            type: "x",
            inertia: true,
            allowNativeTouchScrolling: true,
            onPress() {
                gsap.killTweensOf(spin);
                spin.timeScale(0);
                startProgress = spin.progress();
            },
            onDrag: function() {
                let p = startProgress - (this.startX - this.x) / dragDistancePerRotation;
                spin.progress(progressWrap(p));
            },
            onThrowUpdate: function() {
                let p = startProgress - (this.startX - this.x) / dragDistancePerRotation;
                spin.progress(progressWrap(p));
            },
            onRelease() {
                if (!this.tween || !this.tween.isActive()) {
                    gsap.to(spin, { timeScale: 1, duration: 1 });
                }
            },
            onThrowComplete() {
                gsap.to(spin, { timeScale: 1, duration: 1 });
            }
        });

        return () => {
            window.removeEventListener('resize', checkMediaQueries); // Cleanup listener on unmount
        };
    }, []);

    return (
        <div className="language-section">
            <div className="demoWrapper2">
                <div className="card box2 creative-pro2">
                    <div className="icon">
                        <i className="fa-brands fa-html5"></i>
                    </div>
                    <div className="card-body p-6">Html
                        <small className="card-clr d-block mb-3"><br/>Used as the foundation for structuring most of my projects.</small>
                    </div>
                </div>
                <div className="card box2 creative-pro2">
                    <div className="icon">
                        <i className="fa-brands fa-php"></i>
                    </div>
                    <div className="card-body p-6">Php
                        <small className="card-clr d-block mb-3"><br/>Utilized in various projects, including the development of WordPress plugins and backend scripting.</small>
                    </div>
                </div>
                <div className="card box2 creative-pro2">
                    <div className="icon">
                        <i className="fa-brands fa-js"></i>
                    </div>
                    <div className="card-body p-6">Javascript
                        <small className="card-clr d-block mb-3"><br/>Implemented for dynamic and interactive elements across numerous web applications.</small>
                    </div>
                </div>
                <div className="card box2 creative-pro2">
                    <div className="icon">
                        <i className="fa-brands fa-css3-alt"></i>
                    </div>
                    <div className="card-body p-6">Css
                        <small className="card-clr d-block mb-3"><br/>Applied for designing and styling web pages to ensure a responsive and visually appealing layout.</small>
                    </div>
                </div>
                <div className="card box2 creative-pro2">
                    <div className="icon">
                        <img src="/images/c-sharp.png" className="icon-brand" alt="C#"></img>
                    </div>
                    <div className="card-body p-6">C#
                        <small className="card-clr d-block mb-3"><br/>Focused on developing applications, such as a racing game in a web browser.</small>
                    </div>
                </div>
                <div className="card box2 creative-pro2">
                    <div className="icon">
                        <i className="fa-brands fa-react"></i>
                    </div>
                    <div className="card-body p-6">React
                        <small className="card-clr d-block mb-3"><br/>Primarily worked with this framework for building modern web applications.</small>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Languages2;
