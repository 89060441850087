import React, { useEffect } from 'react';
import './Projects.css';
import { gsap } from 'gsap';

// Données des projets avec leurs images
const projectData = {
    'F4Zero': {
        title: 'F4.Zero',
        description: 'F4.Zero is a racing game that runs directly in your web browser. Inspired by the classic retro racing game F-Zero, F4.Zero challenges players to reach the finish line as quickly as possible. Navigate through various obstacles and take advantage of speed boosts to achieve the best time !',
        images: [
            '/images/F4Zero1.png',
            '/images/F4Zero3.png',
            '/images/F4Zero4.png',
            '/images/F4Zero2.png'
        ]
    },
    'PokemonAlisa': {
        title: 'PokemonAlisa',
        description: 'Step into a world of nostalgia with PokemonAlisa, a retro-inspired site where fans can share and celebrate Pokémon-themed art. Designed as a school project, this unfinished platform evokes the classic charm of navigating an old-school Gameboy, bringing back fond memories for a Pokemon enthusiasts like myself !',
        images: [
            '/images/PokemonAlisa1.png',
            '/images/PokemonAlisa2.png',
            '/images/PokemonAlisa3.png',
            '/images/PokemonAlisa4.png'
        ]
    },
    'Hoplacup': {
        title: 'Hoplacup',
        description: 'Hoplacup is an international water polo tournament that hosts over 800 players from around the globe. To enhance the experience for participants and fans alike, we have created a dedicated website to keep everyone updated on scores and standings in real-time.\n' +
            '\n' +
            'Our website is available in three languages, making it accessible to a diverse audience. You can easily follow your favorite teams, check the latest rankings, and stay informed about the different groups and matches throughout the tournament.',
        images: [
            '/images/Hoplacup1.png',
            '/images/Hoplacup2.png',
            '/images/Hoplacup3.png',
            '/images/Hoplacup4.png'
        ]
    },
    'E-mersion': {
        title: 'E-mersion',
        description: 'E-mersion is a tool designed to assist individuals curious about the developer profession. Users are tasked with creating an escape game page using HTML, CSS, and JavaScript, from which players must escape. This tool is utilized by our university for recruitment, giving prospective students a glimpse of what they will encounter if they join our program.',
        images: [
            '/images/F4Zero1.png',
            '/images/F4Zero3.png',
            '/images/F4Zero4.png',
            '/images/F4Zero2.png'
        ]
    },
    'ToonTank': {
        title: 'Toon Tank',
        description: 'A Web browser racing game',
        images: [
            '/images/F4Zero1.png',
            '/images/F4Zero3.png',
            '/images/F4Zero4.png',
            '/images/F4Zero2.png'
        ]
    },
    'NewSkill': {
        title: 'New Skill',
        description: 'A Web browser racing game',
        images: [
            '/images/F4Zero1.png',
            '/images/F4Zero3.png',
            '/images/F4Zero4.png',
            '/images/F4Zero2.png'
        ]
    },
};

const ProjectsExplanation = ({ project }) => {
    const projectInfo = projectData[project];

    useEffect(() => {
        if (!projectInfo) return;

        const boxes = gsap.utils.toArray('.box');

        gsap.set(boxes, {
            x: (i) => i * 500 // Ajustez cette valeur selon la largeur de vos images
        });

        const animation = gsap.to(boxes, {
            duration: 60,
            ease: 'none',
            x: `+=${boxes.length * 500}`, // Ajustez en fonction du nombre d'images et de leur largeur
            modifiers: {
                x: gsap.utils.unitize(x => parseFloat(x) % (boxes.length * 500))
            },
            repeat: -1
        });

        const wrapper = document.querySelector('.boxes');
        if (wrapper) {
            wrapper.addEventListener('mouseenter', () => animation.pause());
            wrapper.addEventListener('mouseleave', () => animation.resume());

            return () => {
                wrapper.removeEventListener('mouseenter', () => animation.pause());
                wrapper.removeEventListener('mouseleave', () => animation.resume());
            };
        }
    }, [project, projectInfo]);

    return (
        <div className={`projects-explanation-container ${project}`}>
            <h2>
                {projectInfo && <p>{projectInfo.title}</p>}
            </h2>
            <div className="projects-explanation">
                {projectInfo && <p>{projectInfo.description}</p>}
            </div>
            <div className="wrapper">
                <div className="boxes">
                    {projectInfo && projectInfo.images.map((imgSrc, index) => (
                        <div className="box" key={index}>
                            <img src={imgSrc} alt={`Project Image ${index}`} />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ProjectsExplanation;
