import React from 'react';
import './languageExplanation.css';

const languageExplanation = () => {
    return(
        <div>
            <h2>About me</h2>
            <div className="code-snippet-top">
                <div style={{paddingLeft: '30px'}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#002c5e"
                         className="bi bi-circle" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                    </svg>
                </div>
                <div style={{paddingLeft: '10px'}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#002c5e"
                         className="bi bi-circle" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                    </svg>
                </div>
                <div style={{paddingLeft: '10px'}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#002c5e"
                         className="bi bi-circle" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                    </svg>
                </div>
            </div>
            <div className="code-snippet">
                <p className="indent0">My name is Bryan and I'm a 32-year-old French developer.</p>
                <p className="indent0">I am currently in my second year of studying solution and web development.</p>
                <p className="indent0">My passions are video games, space, trivia, and science in general. I've explored
                    various interests over the years, but I've finally found my true calling—coding has become a genuine
                    passion.</p>
                <p className="indent0">In addition to my technical pursuits, I am a creative individual with a
                    background in art, animation, and show creation. I worked as a magician, which further fueled my
                    love for bringing ideas to life.</p>
                <p className="indent0">I thrive on the challenge of transforming creative concepts into reality, and I
                    love that I'm able to do that through programming.</p>
                <p className="indent0">As I continue to grow and develop my skills, I look forward to taking on new
                    challenges and contributing to innovative projects.</p>
            </div>
        </div>
    );
};

export default languageExplanation;